<template lang="pug">
include ../../../configs/template
div.text-left.pa-4
  v-dialog(v-model="dialog" width="500")
    template(#activator="{ on, attrs }")
      v-btn(color="primary" icon v-bind="attrs" v-on="on")
        v-tooltip(top)
          template(#activator='{ on, attrs }')
            v-icon(color="primary" v-bind='attrs' v-on='on') mdi-cellphone-cog
          span {{ $t('controlAccessForSailorCabinet') }}
    v-card
      v-card-title.text-h5.grey.lighten-2 {{ $t('controlAccessForSailorCabinet') }}

      v-card-text.py-4
        +select-validation('body.access_to_functional','listAccessToFunctionalISC','typeAccess','nameLang','["required"]')
      v-divider

      v-card-actions.justify-center
        v-btn(color="success" @click="saveAccessSailorToIscFunctional") {{ $t("btn.save") }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { accessSailorToIscFunctional } from '@/mixins/validationRules'
export default {
  name: 'ChangeAccessToMobApp',
  props: { accessToIscFunctional: { type: Number, default: null } },
  data () {
    return {
      body: {
        access_to_functional: this.accessToIscFunctional || null
      },
      dialog: false
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listAccessToFunctionalISC: state => state.directory.listAccessToFunctionalISC.filter(el => el.is_active)
    })
  },
  validations () { return { body: accessSailorToIscFunctional(this) } },
  methods: {
    ...mapActions(['setAccessSailorToIscFunctional']),
    async saveAccessSailorToIscFunctional () {
      await this.setAccessSailorToIscFunctional({ body: this.body, id: this.$route.params.id })
      this.dialog = false
    }
  }
}
</script>
